import { useEffect } from 'react';

const heights = {};

export const getHeight = (route, query) => {
  const height = heights[JSON.stringify({
    route,
    query,
  })];
  return height ? `${height}px` : undefined;
};

const saveHeight = (route, query, height) => {
  heights[JSON.stringify({
    route,
    query,
  })] = height;
};

export const useSaveHeight = (heightElement, route, query, save = true) => {
  const element = heightElement.current;

  useEffect(() => {
    const height = element?.offsetHeight;
    if (save && height) {
      saveHeight(route, query, height);
    }
  }, [query, element, save, route]);
};
