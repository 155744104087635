import Routes from '../routes.mjs';
import redirectToRoute from './redirectToRoute';
import getJobsRoute from './getJobsRoute';

const correctJobsPath = (request, response, query, asPath) => {
  // eslint-disable-next-line no-underscore-dangle
  const locale = request ? request.locale : window.__NEXT_DATA__.props.locale;

  const route = getJobsRoute(query, locale);

  const actualPath = Routes.findAndGetUrls(
    route,
    query,
  ).urls.as;

  if (asPath !== actualPath) {
    redirectToRoute(route, query, response);
  }
};

export default correctJobsPath;
