import React, { useRef } from 'react';
import { useRouter } from 'next/router';

import HiddenLoader from '@fuww/library/src/HiddenLoader';
import ScrollContainer from '@fuww/library/src/Jobs/ScrollContainer';
import { useSiteContext } from '../components/SiteContext';
import JobSearchPage from '../components/Jobs/JobSearch';
import profileBySlugEBPQuery
from '../lib/queries/products/profileBySlugEbpQuery.graphql';
import cityQuery from '../lib/queries/jobs/cityQuery.graphql';
import positionQuery from '../lib/queries/jobs/positionQuery.graphql';
import withQuery from '../lib/withQuery';
import withApollo from '../lib/apollo';
import getJobsRoute from '../lib/getJobsRoute';
import correctJobsPath from '../lib/correctJobsPath';
import redirectToRoute from '../lib/redirectToRoute';
import { getHeight, useSaveHeight } from '../lib/useSaveHeight';
import Layout from '../components/Layout';
import {
  profileJobsListImageConfigurations,
  profileJobsListLogoConfigurations,
  profileJobsListMobileImageConfigurations,
  cityJobsListImageConfigurations,
} from '../lib/imageConfigurations';

const JobSearchPageWithQuery = withQuery(JobSearchPage, { renderAlways: true });

export const Jobs = () => {
  const { query, route } = useRouter();
  const heightElement = useRef(null);

  const {
    companySlug, citySlug, positionSlug, profilePage,
  } = query;
  const { jobsLocale: locale } = useSiteContext();

  const jobsPageComponent = () => {
    if (companySlug) {
      return (
        <JobSearchPageWithQuery
          query={profileBySlugEBPQuery}
          variables={{
            slug: companySlug,
            imageConfigurations: profileJobsListImageConfigurations,
            mobileImageConfigurations: profileJobsListMobileImageConfigurations,
            logoConfigurations: profileJobsListLogoConfigurations,
          }}
          errorMessage="Error loading jobs"
          loader={<HiddenLoader />}
          field="profileBySlug"
          readyFetchPolicy="network-first"
        />
      );
    }

    if (citySlug) {
      return (
        <JobSearchPageWithQuery
          query={cityQuery}
          variables={{
            slug: citySlug,
            locales: [locale],
            imageConfigurations: cityJobsListImageConfigurations,
          }}
          errorMessage="Error loading jobs"
          loader={<HiddenLoader />}
          field="cityBySlug"
          readyFetchPolicy="network-first"
        />
      );
    }

    if (positionSlug) {
      return (
        <JobSearchPageWithQuery
          query={positionQuery}
          variables={{ slug: positionSlug, locales: [locale] }}
          errorMessage="Error loading jobs"
          loader={<HiddenLoader />}
          field="positionBySlug"
          readyFetchPolicy="network-first"
        />
      );
    }

    return <JobSearchPage showLoader={false} />;
  };

  useSaveHeight(heightElement, route, query, !profilePage);

  return (
    <ScrollContainer
      ref={heightElement}
      style={{
        minHeight: profilePage ? null : getHeight(route, query),
      }}
    >
      {jobsPageComponent()}
    </ScrollContainer>
  );
};

const nestedParameterNames = ['companySlug', 'citySlug', 'positionSlug'];
const allowOneNesting = (request, response, originalQuery) => {
  const nestedParameters = nestedParameterNames
    .map((name) => originalQuery[name]);

  if (nestedParameters.filter(Boolean).length > 1) {
    const firstParameterName = nestedParameterNames
      .find((name, index) => nestedParameters[index]);
    const parametersToRemove = nestedParameterNames
      .filter((parameterName) => firstParameterName !== parameterName);
    const query = parametersToRemove
      .reduce((currentQuery, parameterToRemove) => ({
        ...currentQuery,
        [parameterToRemove]: null,
      }), originalQuery);

    // eslint-disable-next-line no-underscore-dangle
    const locale = request ? request.locale : window.__NEXT_DATA__.props.locale;

    redirectToRoute(
      getJobsRoute(query, locale),
      query,
      response,
    );
  }
};

Jobs.getLayout = (page) => <Layout layoutMaxWidth="unset">{page}</Layout>;

Jobs.getInitialProps = ({
  req, res, query, asPath,
}) => {
  correctJobsPath(req, res, query, asPath);
  allowOneNesting(req, res, query);

  return {};
};

export default withApollo(Jobs);
