import styled from '@emotion/styled';

const ScrollContainer = styled.div`
  display: flex;
  flex-direction: column;
  && > * {
    flex-grow: 0;
  }
`;

export default ScrollContainer;
